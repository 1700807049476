import { AccountPage } from "@components/account-page/account-page";
import BTThemeProvider from "@components/bt-theme-provider";
import { SideBarItem } from "@components/sidebar/sidebar";
import { latestChargebeeSubscriptionSelector } from "@reducers/UserSelectors";
import { useAppSelector } from "@store/hooks";
import AdminBillingView from "@views/admin-billing/admin-billing";
import ChargebeeAdminPortal from "@views/chargebee-admin-portal/chargebee-admin-portal";

export const UnifiedAdminBilling = () => {

    const latestChargebeeSubscription = useAppSelector(latestChargebeeSubscriptionSelector) ?? null;    

    return (
        <BTThemeProvider>
            <AccountPage
                page={latestChargebeeSubscription?.type === 'chargebee' ? <ChargebeeAdminPortal /> : <AdminBillingView />}
                active={SideBarItem.AdminBilling}
            ></AccountPage>
        </BTThemeProvider>
    );
}